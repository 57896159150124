@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0px;
}
.Resizer {
  z-index: 10000;
  box-sizing: border-box;
  background-clip: padding-box;
}

.Resizer:hover {
  transition: all 0.1s ease;
  border: 10px solid transparent;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  cursor: row-resize;
  width: 100%;
  border: 2px solid transparent;
}

.Resizer.vertical {
  margin: 0 -5px;

  cursor: col-resize;
  border: 2px solid transparent;
}

.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}
